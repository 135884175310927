import React, { useEffect } from 'react'

import { useUKAccess } from '@/modules/useUKAccess'

import Article from '../screens/article'

const UniversityPost = (props) => {
  const article = props.pageContext.article
  const isUKIp = useUKAccess()

  useEffect(() => {
    let isDerivativesUniversity = article.categories.nodes.some(
      (cat) => cat.slug === 'derivatives-university-en'
    )
    if (isUKIp && isDerivativesUniversity) {
      window.location.pathname = `/404`
    }
  }, [isUKIp, article.categories.nodes])

  return <Article {...props} article={article} category="university" />
}

export default UniversityPost
